$primary-color: #ffffff;
$background-color: #0C0C0C;
$secondary-color: #171717;
$tertiary-color: #ff0000;
$near-black: #020202;
$tablet-width: 769px;
$desktop-width: 1000px;

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('woff2');
  font-weight: 100 900;
}

@font-face {
  font-family: 'Fira Mono Medium';
  src: url('./assets/fonts/Fira_Mono/FiraMono-Medium.ttf');
}

@font-face {
  font-family: 'Fira Mono Bold';
  src: url('./assets/fonts/Fira_Mono/FiraMono-Bold.ttf');
}

body {
  background-color: $background-color;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

button{
  &:hover{
    filter: brightness(60%);
    cursor: pointer;
  }
}
