@import '../../App.scss';

.footer {
    margin-top: 50px;
    width: 100%;
    height: 80px;
    background-color: $secondary-color;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    .footer-text {
        color: #dad7d7;  
    }

    .adminLink {
        align-self: flex-start;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-decoration: none;
        font-size: 14px;
        color: #bcbaba;
        margin-left: 5px;
        
        .admin-icon-wrapper {
            width: 15px;
            height: 15px;
        }
        svg {
            filter:brightness(50%);
            width: 100%;
            height: 100%;
        }
    }
}