@import '../../../App.scss';

.project-item-container {
    width: 100%;
    height: 100%;
    background-color: $secondary-color;
    border-radius: 5px;
    border: 2px solid $near-black;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding-left: 15px; padding-right: 15px;

    a {
        width: 200px;
        height: 200px;
        position: absolute;
        align-self: center;
        transform: translateY(40px);
        display: none;
        text-decoration: none;
        color: $primary-color;
        font-size: 20px;
        font-weight: 400;
        

    }

    &:hover {
        div {
            filter: brightness(15%);
        }
        transform: scale(1.015);
        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .project-title-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: red;

        .project-title {
            font-size: 20px;
        }
    }
    .project-description-wrapper {
        margin-top: -15px;
        //background-color: red;
        text-align: start;
      
        height: 150px;
        overflow: hidden;
        .project-description {   
            padding: 0;
            font-size: 14px;
            white-space: pre-line; /* Preserve line breaks and wrap text as needed */
            word-wrap: break-word; /* Allow word wrapping */
            line-height: 1.5;     
        }
    }
    
    .project-tech-wrapper {
        //background-color: red;
        text-align: start;
      
        height: 65px;
        overflow: hidden;
        .project-tech {   
            font-size: 14px;
            white-space: pre-line; /* Preserve line breaks and wrap text as needed */
            word-wrap: break-word; /* Allow word wrapping */
            line-height: 1.5;     
        }
    }
}

@media screen and (min-width: $tablet-width){
    .project-item-container {
        width: 350px;
        height: 100%;
        background-color: $secondary-color;
        border-radius: 5px;
        border: 2px solid $near-black;
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);

        .text-overlay {
            transform: translateY(100px);
        }

        .project-description-wrapper {
            margin-top: -10px;
            //background-color: red;
            height: 165px;
        }
    }

    
}