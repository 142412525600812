@import '../../App.scss';

 .header{
    //background-color: red;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 10vh;

    .text-back-drop{
        background-color: $secondary-color;
        width: 168px;
        height: 20px;
        border-radius: 40px;
        p{
            position: relative;
            transform: translate(0, -35px);
            color: $primary-color;
            font-family: 'Fira Mono Medium', 'Inter', sans-serif;
            font-size: 22px;
        }
    }

    
}


@media screen and (min-width:576px){
    .header{
    //background-color: red;
    height: 130px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 150px;
    }
}

