@import '../../App.scss';

.login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-form {
        width: 250px;
        height: 250px;
        background-color: $secondary-color;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        padding: 20px;
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
        border-radius: 5px;

        div {
            display: flex;
            flex-direction: column;
            height: fit-content;
            gap: 2px;
            
        }

        .button-wrapper {
            align-items: center;
            .login-button {
                height: 30px;
                width: 80px;
                border-radius: 80px;
                background-color: $secondary-color;
                border: 1.5px solid $primary-color;
                color: $primary-color
            }
        }

        
    }
}