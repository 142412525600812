@import '../../../App.scss';

body.modal-disable-scroll{
    overflow-y: hidden;
}

.job-edit-background {
    background-color: #21202082;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 0 !important;
    overflow: hidden;
}

.job-edit-modal {
    background-color: $near-black;
    height: 660px;
    width: 350px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;

    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: fit-content;
    }

    .close-button {
        width: 20px;
        height: 20px ;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-color;
        border-radius: 80px;
        border: 1.5px solid $primary-color;
        color: $primary-color;
        svg {
            width: 10px;
            height: 10px;
        }
    }

}

.job-edit-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr auto auto;
    row-gap: 20px;
    column-gap: 15px;
    transform: translateY(-5px);
    width: 335px;
    height: fit-content;
    align-self: center;
    
    div {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        input, textarea {
            margin-top: 10px;
            font-size: 14px;
            padding: 2px;
            resize: vertical;
        }

    }

 
}

textarea {
    font-family: 'Inter';
}
.save-button {
    align-self: center;
    border-radius: 50px;
    height: 40px;
    width: 80px;
    border: 2px solid $primary-color;
    color: $primary-color;
    background-color: $background-color;
}

.company-name-container {
    grid-area: 1/1 / 1/2;
}

.job-title-container {
    grid-area: 1/2 / 1/3;
}

.job-start-container {
    grid-area: 2/1 / 2/2;
}

.job-end-container {
    grid-area: 2/2 / 2/3;
}

.job-description-container {
    grid-area: 3/1 / 3/3;
    height: fit-content;
    textarea {
        height: 150px;
    }
}

.save-button-container {
    grid-area: 4/1 / 4/3;
}


@media  screen and (min-width: $desktop-width) {
    .job-edit-modal {
        width: 600px;
        height: 530px;
    }
}

