@import '../../App.scss';

.experience-title-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 125px;
    //background-color: red;
    .experience-title {
       font-weight: 600;
    }
}

.experience-dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    //background-color: red;

    *:not(:first-child) {
        margin-top: 15px;
      }
}

@media screen and (min-width: $tablet-width){
    .experience-title-container {
        height: 200px;
        font-size: 25px;
    }
    
}

