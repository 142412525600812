@import '../../../App.scss';
@import '../admin.scss';

.job-preview-container {
    background-color: $secondary-color;
    width: $item-container-width-mobile;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;

    p {
        font-size: 14px;
        margin-left: 5px;
        font-weight: 500;
    }

    .job-title {
        margin-left: 35px;
    }



}

    
@media  screen and (min-width: $desktop-width) {
    .job-preview-container {
        width: $item-container-width-desktop;
        
    }

    
}