@import '../../../App.scss';

$item-inactive-height-mobile: 65px;
$item-width-mobile: 340px;
$item-border-radius: 5px;

$item-width-desktop: 640px;

.experience-item-container {
    background-color: $near-black;
    min-height: $item-inactive-height-mobile;
    width: $item-width-mobile;
    border-radius: $item-border-radius;
    border: 2px solid $near-black;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
    
    .experience-item-header {
        background-color: $secondary-color;
        height: $item-inactive-height-mobile;
        width: $item-width-mobile - 4;
        border-radius: $item-border-radius;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding-left: 5px; padding-right: 5px;
        &:hover {
            filter: opacity(80%);
            cursor: pointer;
        }

        p {
        
            font-size: 14px;
            font-weight: 300;
        }


    }

    .job-description-container {
        height: fit-content;
        padding-left: 5px; padding-right: 5px;
        
        p {
            font-size: 14px;
            font-weight: 300;
            white-space: pre-line; /* Preserve line breaks and wrap text as needed */
            word-wrap: break-word; /* Allow word wrapping */
            padding: 10px; /* Add padding for spacing */
            height: fit-content; /* Set a maximum height to enable scrolling */
            line-height: 1.5;
        }
    }
    
}

@media screen and (min-width: $desktop-width){
    .experience-item-container {
        width: $item-width-desktop;
        .experience-item-header {
            width: $item-width-desktop - 3.5;
            a {
                width: $item-width-desktop;
            }
            p {
                font-size: 17px;
                font-weight: 400;
            }
        }
        .job-description-container {
            padding-left: 15px; padding-right: 15px;
            p {
                font-size: 16px;
                font-weight: 300;
            }
        }

    }

    
    
}