@import '../../App.scss';

$item-container-width-mobile: 330px;
$item-container-width-desktop: 500px;

.admin-page {
    display: flex;
    flex-direction: column;
    padding:20px;
}

.back-link {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $secondary-color;
    color: $primary-color;
    border-radius: 5px;
    padding: 10px;
    border: none;
    svg {
        width: 20px;
        height: 20px;
    }
}

.home-link {
    position: absolute;
    top: 45px;
    left: 5px;
    width: 100px;
    height: 30px;
    background-color: $secondary-color;
    color: $primary-color;
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    svg {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
    }
}

.jobs-header {
    margin-top: 50px;
}
.projects-header {
    margin-top: 20px;
}
.jobs-header, .projects-header {
    width: $item-container-width-mobile;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


@media screen and (min-width: $desktop-width) {
    .admin-page{
        align-items: center;
    }

    .jobs-header, .projects-header {
        width: $item-container-width-desktop;
    }
    
    .home-link {
        position: absolute;
        top: 5px;
        left: 115px;

    }
    
}