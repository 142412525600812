@import '../../App.scss';

.about-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: none;
    justify-content: space-between;
}

.about-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    //background-color: red;
    .about-title {
       font-weight: 600;
    }
}

.about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 10px 20px 20px 20px;

    .text-container {
        height: fit-content;
    
        p {
            font-size: 14px;
            font-weight: 300;
            white-space: pre-line; /* Preserve line breaks and wrap text as needed */
            word-wrap: break-word; /* Allow word wrapping */
            padding: 10px; /* Add padding for spacing */
            line-height: 1.5;
        }

        .text-larger {
            font-size: 20px;

            
        }

        .text-large {
            font-size: 16px;
        }

    }

    .image-container {
        display: none;
    }
  
}



.button-container {
   // background-color: red;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-button {
        height: 60px;
        width: 140px;
        font-size: 18px;
        background-color: $near-black;
    }

    

}

@media screen and (min-width: $tablet-width){
    .about-title-container {
        height: 150px;
        font-size: 25px;
    }

    .about-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .about-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 900px;
        padding-bottom: 10px;
    
        .text-container {
            grid-area: 1/ 1 / 1 / 1;
    
        }
    
        .image-container {
            grid-area: 1/ 2 / 1 / 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 25px;
            align-items: flex-end;
            padding-right: 50px;

            .me-aesthetic-img {
                width: 200px;
                height: 200px;
                object-fit: cover;
                transform: translateX(25px);
                box-shadow: 3px 3px 5px 8px rgba(0, 0, 0, 0.5);

            }

            .me-vball-img {
                width: 250px;
                height: 200px;
                object-fit: cover;
                object-position: 0 75%;
                transform: translateX(-5px);
                box-shadow: 3px 3px 5px 8px rgba(0, 0, 0, 0.5);

            }

            .wp-team-img {
                width: 200px;
                height: 200px;
                object-fit: cover;
                transform: translateX(40px);
                box-shadow: 3px 3px 5px 8px rgba(0, 0, 0, 0.5);

            }
        }
    }
}
