@import '../../../App.scss';


body.modal-active {
    overflow-y: hidden;
}

.contact-form-modal {
    background-color: #00000082;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center !important;
    z-index: 999;
    padding: 0 !important;
    overflow: hidden;

    form,div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.contact-modal-wrapper {
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-color;
        border-radius: 80px;
        border: 1.5px solid $primary-color !important;
        height: 50px;
        width: 90px;
        color: $primary-color;
        font-weight: 600;
        }
}


.contact-form {
    background-color: $near-black;
    height: 580px;
    max-height: 80vh;
    width: 350px;
    border-radius: 35px;
    overflow-y: auto;

    .close-button {
        margin-left: 20px;
        align-self: flex-start;
        transform: translate(-2px,12px);
        width: 20px !important;
        height: 20px !important;
        svg {
            width: 10px;
            height: 10px;
        }
    }

    .title {
        margin-top: 40px;
    }

        div {
            padding-top: 5px;
            label {
                align-self: flex-start;
                padding-top: 5px; padding-bottom: 10px;
                margin-left: 15px;
                font-size: 16px;
                font-weight: 550;
            }
    
            input, textarea {
                width: 260px;
                height: 30px;
                border-radius: 10px;
                border: 2px solid $primary-color;
                background-color: $secondary-color;
                color: $primary-color;
                padding: 5px 10px 5px 10px;
                font-size: 14px;
                font-family: 'Inter';
    
                &:focus {
                    outline: none;
                }
            }
    
            .message-input {
                resize: vertical;
                min-height: 145px;
                word-wrap: break-word;
                line-height: 1.5;
                
            }

    
    }

    
    .submit-button {
        min-height: 50px;
        width: 160px !important;
        margin-top: 20px;
        margin-left: 0 !important;
    }

    
}



@media screen and (min-width:$tablet-width) {
    
    .contact-form {
        height: 650px;
        width: 450px;
        padding: 0 !important;
        
        .title{
            margin-top: 30px;
        }
        div {
            padding-left: 0 !important;
            padding-right: 0 !important;

            input {
                height: 40px;
            }
            input, textarea {
                width: 350px;
            }
        }
        .close-button {
            svg {
                width: 20px;
                height: 20px;
            }
        }

    
      
    }
}


