@import '../../App.scss';

$self-img-size-desktop: 270px;
$self-img-size-tablet: 220px;

.hero-section-container{
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .hero-section-grid{
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 3fr 1fr  1fr;
        background-color: $secondary-color;
        padding-bottom: 15px;


        div {
            display: flex;
            align-items: center;
            justify-content: center;
            
            padding-left: 15px;
            padding-right: 15px;
            p {
                min-width: none;
                max-width: 500px;
                font-size: 22px;
            }
        }

        .intro {
            grid-area: 1/1 / 2/1;
            flex-direction: column;
            justify-content: center;
            .text-smaller {
                font-size: 18px
            }
            .title {
                font-size: 34px;
                font-weight: 700;
                margin-top: 35px;
            }
         
        }

        .about-cta {
            grid-area: 2/1 /3/1;

            
            .about-cta-button {
                width: 170px;
                font-size: 12px;
                
                .anchor {
                    position: absolute;
                    border-radius: inherit;
                    width: inherit;
                    height: inherit;
                }
            }
            svg{
                width: 40px;
                height: 40px;
                transform: translatex(10px);
                
            }
        }

        .image {
           display: none;
          // width: 15px;
          // height: 15px;
        }

        .school {
            grid-area: 3/1 / 3/1;
            p {
                font-size: 20px;
                color: #ffffff8a;
            }
            span {
                margin-left: 15px;
            }
            
        }
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $background-color;
            border-radius: 80px;
            border: 1.5px solid $primary-color;
            margin-left: 15px;
            height: 50px;
            width: 90px;
            color: $primary-color;
            font-weight: 600;
        }

        .connect {
            grid-area: 4/1 /4/1;
            
            
            .social {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
            .github{
                margin-left: 15px;
            }

            
            .resume-button{
                a {
                    color: $primary-color;
                    text-decoration: none;
                    font-weight: 600;
                }     
            }

        }

        
    }
}
@media screen and (min-width: $tablet-width) {
    .hero-section-container {
        height:fit-content;
        .hero-section-grid {
            display: grid;
            height: 100%;
            width: 90%;
            border-radius: 15px;
            grid-template-columns: 5fr 4fr;
            grid-template-rows: 6fr 1fr 2fr;
            grid-gap: 10px 0px;
            grid-template-areas:
            'intro img'
            'school img'
            'connnect about-cta';
            div {
                display: flex;
                justify-content: center;
            }
            .intro {
                padding-top: 70px;
                grid-area: 1 / 1 / 2 / 2;
                p {
                    font-size: 22px;
                    max-width: 600px;
                }
                .text-smaller {
                    align-self: flex-start;
                    font-size: 18px
                }
                .title {
                    font-size: 40px;
                }
            }
        
            .image {
                display: flex;
                grid-area: 1 / 2 / 3 / 2;
                align-self: center;
                .img-back-drop{
                    width: $self-img-size-tablet;
                    height: $self-img-size-tablet;
                    padding: 0;
                    background-color: #00000000;
                    border: 2px solid $primary-color;
                    transform: translate(40px,40px);
                    .img-container {
                        position: relative;
                        transform: translate(-40px, -40px);
                        width: $self-img-size-tablet;
                        height: $self-img-size-tablet;
                        object-fit: cover;
                        box-shadow: 3px 3px 5px 4px rgba(0, 0, 0, 0.5);
                        overflow: hidden;
                    }
                    img{
                        transform-origin: 35% 25%;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transform: scale(1.8);

                    

                    }
                }
            }
            

            .school {
                grid-area: 2/1 / 2/1;
                justify-content: flex-start;
            }
        
            .connect {
                justify-self: flex-start;
                grid-area: 3/1 / 4/1;
                .social {
                    &:hover{
                        filter: opacity(70%);
                    }   
                }
               
            }
            .about-cta {
                grid-area: 3/2 / 4/2;
                .about-cta-button {
                    width: 200px;
                    font-size: 14px;

                }
                svg{
                    width: 42px;
                    height: 42px;
                    transform: translatex(15px);
                }
            }
        }
    }
}
@media screen and (min-width:$desktop-width) {
    .hero-section-container {
        

        .hero-section-grid {
            display: grid;
            max-width: 1200px;
            grid-gap: 20px 30px;

            .intro, .school, .connect {
                justify-self: flex-start;
                padding-left: 55px;
                padding-right: 30px;
            }



            .intro {
          
                p {
                    font-size: 22px;
                }
            }
        
            .image {
                .img-back-drop{
                    width: $self-img-size-desktop;
                    height: $self-img-size-desktop;
                    .img-container {
                        position: relative;
                        transform: translate(-30px, -30px);
                        width: $self-img-size-desktop;
                        height: $self-img-size-desktop;
                        object-fit: cover;
                        box-shadow: 3px 3px 5px 4px rgba(0, 0, 0, 0.5);
                        overflow: hidden;
                    }
                 
                }
                
            }
            button {
                height: 50px;

            }

            .school {
                grid-area: 2/1 / 2/1;
                justify-content: flex-start;
                p {
                    font-size: 18px;
                }
            }
            
            .connect {
                .social {
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;  
                }
                .github{
                    margin-left: 30px;
                }
                
                
                .resume-button{   
                    width: 120px;
                    margin-left: 45px;
                    a{
                        font-size: 14px;
                    }     
                }
                .contact-button{
                    width: 150px;
                    margin-left: 20px;
                    font-size: 14px;
                }
            }
            .about-cta {                
                .about-cta-button {
                    width: 200px;
                    font-size: 14px;
                    z-index: 0;
                }
                svg{
                    width: 45px;
                    height: 45px;
                    transform: translatex(15px);
                }
            }
            
        }
        
    }
}

